// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-templates-posts-page-layout-js": () => import("./../src/components/templates/posts-page-layout.js" /* webpackChunkName: "component---src-components-templates-posts-page-layout-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-files-js": () => import("./../src/pages/files.js" /* webpackChunkName: "component---src-pages-files-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jsonlint-js": () => import("./../src/pages/jsonlint.js" /* webpackChunkName: "component---src-pages-jsonlint-js" */),
  "component---src-pages-miscdocs-js": () => import("./../src/pages/miscdocs.js" /* webpackChunkName: "component---src-pages-miscdocs-js" */),
  "component---src-pages-techdocs-js": () => import("./../src/pages/techdocs.js" /* webpackChunkName: "component---src-pages-techdocs-js" */),
  "component---src-pages-webapps-js": () => import("./../src/pages/webapps.js" /* webpackChunkName: "component---src-pages-webapps-js" */)
}

